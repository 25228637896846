.IndaLogo {
  text-align: center;
  width: 100%;
  margin-top: 2rem;

  .logo {
    max-width: 320px;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
  }
}
