.Loading {
  max-width: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1.3em;
  }
}
