.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    text-align: center;
    margin: 3em 1em 2em;
  }

  .input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
  }

  --input-width: 30px;
  --input-height: 50px;
  @media (max-width: 380px) {
    --input-width: 25px;
    --input-height: 40px;
  }
  .input-group > label,
  .input-group > span {
    margin-right: 0.4em;
    @media (max-width: 380px) {
      margin-right: 0.3em;
    }
  }
  .input-group > label:last-child,
  .input-group > span:last-child {
    margin-right: 0;
  }

  .hyphen {
    background: var(--contrastLow);
    height: 0.1em;
    width: 0.5em;
    display: inline-block;
  }

  label {
    width: var(--input-width);
    height: var(--input-height);
    position: relative;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: var(--input-width);
    height: var(--input-height);
    opacity: 0;
  }
  label > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: var(--input-width);
    height: var(--input-height);

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: 3px;
    background-color: #fff;

    font-family: 'Courier New', Courier, monospace;
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--secondaryAlt);
  }
  label:focus-within > div {
    box-shadow: inset 0 2px 5px 0 rgba(9, 30, 66, 0.2);
    background-color: white;
    color: black;
    outline: none;
  }
  hr {
    border: none;
    position: absolute;
    bottom: 15%;
    right: 15%;
    left: 15%;
    height: 3px;
    opacity: 0.3;
    background-color: var(--secondaryAlt);
    margin: 0;
    padding: 0;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  label:focus-within > hr {
    animation: blink 1s infinite reverse;
    animation-timing-function: linear;
    background-color: var(--primary);
  }

  .send-btn {
    font-size: 1.2em;
    min-width: 200px;
    margin-top: 2em;
  }

  .send-again-btn {
    font-size: 1.1em;
    margin-top: 1em;
    color: white;
    text-decoration: underline !important;
    background: none !important;
  }

  .error {
    color: red;
    font-size: 1.2em;
    padding: 1rem 0;
  }

  .code {
    width: 100%;
    height: 50px;
    display: flex;
    font-weight: 500;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;
    direction: ltr;

    > div {
      gap: 0.5rem;
    }

    input {
      font-size: 1.5em;
      direction: ltr;
      width: var(--input-width) !important;
      height: var(--input-height);
      position: static;
      opacity: 1;
    }
  }

}
