:root {
  --primary: #0d9aff;
  --background: #001a72;
  --surface: #1f242e;
  --surfaceAlt: #14181e;
  --contrastLow: #d1d6e0;
  --contrastHigh: #ffffff;
  --secondaryAlt: #535f7a;
  --rubik: "Rubik", sans-serif;
}

body {
  margin: 0;
  font-family: var(--rubik);
  color: var(--contrastHigh);
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.ltr,
.ltr * {
  direction: ltr;
}
