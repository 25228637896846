.Share {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4em;
  padding: 0 1.1rem;

  > div {
    text-align: initial;
  }

  p.contact-label,
  .link {
    color: white;
    font-size: 1em;
    margin: 0.2em 0.5em;
    max-width: none;
  }

  .link {
    text-decoration: underline;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}
