.UploadComplete {
  max-width: 500px;
  text-align: center;

  h1 {
    font-size: 2em;
    padding: 0 1rem;
  }

  p {
    font-size: 1.4em;
    margin: 1.6em  auto 0.4em;
    max-width: 220px;
  }

  .upload-btn {
    font-size: 1.2em;
    min-width: 200px;
  }
}
