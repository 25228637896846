.Header {
  width: 100%;
  text-align: center;
  background: #fff;
  box-sizing: border-box;
  padding: 0.7em 1em 0.3em;
  position: sticky;
  top: 0;

  .HeaderContent {
    max-width: 500px;
    margin: 0 auto;
    position: relative;

    &.AlignRight {
      text-align: right;
    }
    .Logo {
      width: 210px;
    }
  }
}
