.language {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  direction: ltr;

  .ChangeLang {
    cursor: pointer;
    color: #000;
    width: 30px;

    svg {
      width: 30px;
    }

    svg path{
      fill: #000;
    }
  }
}
