.MoreInfo {
  text-align: center;

  h1 {
    font-size: 2em;
  }

  label {
    display: block;
    font-size: 1.4em;
    margin: 1em  auto 0.2em;
    max-width: 350px;
  }

  .datetime input{
    direction: ltr;
  }
}
