.Contact {
  text-align: center;
  margin-bottom: 3em;
  p.contact-label,
  a.mailto-link {
    color: white;
    font-size: 1em;
    margin: 0.2em;
    max-width: none;
  }
}
