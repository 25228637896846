.Upload {
  max-width: 500px;
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;

  label {
    display: block;
    font-size: 1.4em;
    margin: 1.6em  auto 0.4em;
    max-width: 300px;
  }

  .Buttons {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-bottom: 0;
  }

  .disabled {
    pointer-events: none;
  }

  .buttonToggle {
    width: 370px;
    max-width: 90vw;
  }

}

iframe {
  visibility: hidden;
  top: -50px;
}
