.Intro {
  max-width: 600px;
  h1 {
    font-size: 2rem;
    font-weight: 400;
    margin: 1em 0.5em 1em;
    text-align: center;
  }
  p {
    font-size: 1.3em;
    margin: 0.5em 0.5em 1em;
    white-space: pre-line;
  }
  .btn-more {
    font-size: 1.3em;
    color: white;
  }
  .btn-continue {
    font-size: 1.2em;
    margin: 1em 1em 2em;
    min-width: 200px;
  }
  .MuiButton-startIcon {
    margin-left: 0.2em;
  }
}
