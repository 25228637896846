.Preview {
  position: relative;
  max-width: 500px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2em;
  }

  .slide {
    transform: scaleX(1);
  }

  .slider {
    width: 100%;

    .slide svg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      cursor: pointer;
      filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 0.4));
    }

    .carousel {
      position: relative;
    }

    .carousel div {
      height: 150px;
    }

    div.image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    div.others {
      direction: ltr;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;

      svg {
        position: static;
        cursor: default;
      }

      .text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
    .prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }

    .next,
    .prev {
      background: transparent;
      border: 0;
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      svg {
        filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 0.4));
      }

      path {
        fill: white;
      }

      &:disabled {
        opacity: 50%;
        pointer-events: none;
      }
    }
  }

  .Buttons {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-bottom: 0;

    .upload-btn {
      width: 100%;
      height: 60px;
    }
  }

  .uploadMore {
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.5em;
      border: 2px solid #fff;
    }
  }

  .react-file-reader-button {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scaleX(-1);
  }
}
